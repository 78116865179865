import css from './index.module.css'
import {
    DatatableWrapper,
    Pagination,
    TableBody,
    TableColumnType,
    TableHeader
} from "react-bs-datatable";
import {Col, Modal, Table} from "react-bootstrap";
import chevronsDown from '../../assets/chevrons-down.svg';
import arrowLeft from '../../assets/arrow-left.svg';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ReducerStateIF } from '../../redux/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import API from '../../redux/services/Api';
import moment from 'moment';
import { toast } from 'react-toastify';

type UserData = {
    srno: string;
    timedate: string;
    status: string;
    viewCount: string;
    downloadCount: string;
    notification: any;
    images: any;
    aiimages: any;
};
const UserDetail = () => {

    const { id } = useParams()
    const navigate = useNavigate();

    const headers: TableColumnType<UserData>[] = [
        {title: 'Sr No.', prop: 'srno'},
        {title: 'Time & Date', prop: 'timedate'},
        {title: 'Status', prop: 'status'},
        {title: 'View All Count', prop: 'viewCount'},
        {title: 'Download Count', prop: 'downloadCount'},
        {title: 'Reminder', prop: 'notification'},
        {title: 'Images by User', prop: 'images'},
        {title: 'Ai Generated', prop: 'aiimages'}
    ];

    const userDetails = useSelector((state:ReducerStateIF) => state.app);
    const dispatch:any = useDispatch();
    const [imageModal, setImageModal] = useState(false)
    const [body, setBody] = useState<any>([]);
    const [imageList, setImageList] = useState<any>([]);
    const [userData, setUserData] = useState<any>(null)

    const closeImageModal = () => {
        setImageModal(false)
        setImageList([]);
    }

    const sentReminderNotification = async(imageId:number) => {
        try {
            const response:any = await API.Post("admin/notification-reminder", {imageId: imageId}, userDetails, dispatch)
            if(response.status === "success"){
                toast.success(response.message)
            }else{
                toast.error(response.message)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const getUserData = async(userId:string) => {
        const response:any = await API.Post("admin/users-history", {userId: userId}, userDetails, dispatch)
        if(response.status === "success"){
            const tmpData = response.data.data;
            setUserData(response.data.userdata)

            const bodyData = tmpData.map((item:any, index:number) => {
                
                var today = moment();
                var yesterday = moment().subtract(1, 'day');
                var createdAt = moment(item.createdAt).format("hh:mm A, YYYY-MM-DD")
                
                if(moment(item.createdAt).isSame(today, 'day')){
                    createdAt = moment(item.createdAt).format("hh:mm A, ")+' Today';
                }else if(moment(item.createdAt).isSame(yesterday, 'day')){
                    createdAt = moment(item.createdAt).format("hh:mm A, ")+' Yesterday';
                }
                return {
                    srno: (<span>{item.srno}</span>),
                    timedate: (<span>{createdAt}</span>),
                    status: (<span>{item.status}</span>),
                    viewCount: (<span>{item.viewcount}</span>),
                    downloadCount: (<span>{item.imageviewcount}</span>),
                    notification: (
                        <>
                        {(item.status == "submited") ?(
                            <button type='button' className='notificationIconBtn' onClick={() => sentReminderNotification(item._id)}>
                                <svg  height="20px" width="20px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" 
                                        viewBox="0 0 611.999 611.999" xmlSpace="preserve">
                                    <g>
                                        <g>
                                            <g>
                                                <path d="M570.107,500.254c-65.037-29.371-67.511-155.441-67.559-158.622v-84.578c0-81.402-49.742-151.399-120.427-181.203
                                                    C381.969,34,347.883,0,306.001,0c-41.883,0-75.968,34.002-76.121,75.849c-70.682,29.804-120.425,99.801-120.425,181.203v84.578
                                                    c-0.046,3.181-2.522,129.251-67.561,158.622c-7.409,3.347-11.481,11.412-9.768,19.36c1.711,7.949,8.74,13.626,16.871,13.626
                                                    h164.88c3.38,18.594,12.172,35.892,25.619,49.903c17.86,18.608,41.479,28.856,66.502,28.856
                                                    c25.025,0,48.644-10.248,66.502-28.856c13.449-14.012,22.241-31.311,25.619-49.903h164.88c8.131,0,15.159-5.676,16.872-13.626
                                                    C581.586,511.664,577.516,503.6,570.107,500.254z M484.434,439.859c6.837,20.728,16.518,41.544,30.246,58.866H97.32
                                                    c13.726-17.32,23.407-38.135,30.244-58.866H484.434z M306.001,34.515c18.945,0,34.963,12.73,39.975,30.082
                                                    c-12.912-2.678-26.282-4.09-39.975-4.09s-27.063,1.411-39.975,4.09C271.039,47.246,287.057,34.515,306.001,34.515z
                                                    M143.97,341.736v-84.685c0-89.343,72.686-162.029,162.031-162.029s162.031,72.686,162.031,162.029v84.826
                                                    c0.023,2.596,0.427,29.879,7.303,63.465H136.663C143.543,371.724,143.949,344.393,143.97,341.736z M306.001,577.485
                                                    c-26.341,0-49.33-18.992-56.709-44.246h113.416C355.329,558.493,332.344,577.485,306.001,577.485z"/>
                                                <path d="M306.001,119.235c-74.25,0-134.657,60.405-134.657,134.654c0,9.531,7.727,17.258,17.258,17.258
                                                    c9.531,0,17.258-7.727,17.258-17.258c0-55.217,44.923-100.139,100.142-100.139c9.531,0,17.258-7.727,17.258-17.258
                                                    C323.259,126.96,315.532,119.235,306.001,119.235z"/>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </button>
                        ):(
                            <span>-</span>
                        )}
                        </>
                    ),
                    images: (
                        <div>
                            {((item.images.length > 4)?item.images.slice(0, 4):item.images).map((img:any, imgIndex:number) => (
                                <img key={"img_"+imgIndex} src={img} alt='' className={css.uploadImage}/>
                            ))}
                        </div>
                    ),
                    aiimages: (
                        <div className="d-flex align-items-center">
                            <img alt="" src={chevronsDown} className={`p-2 me-2 ${css.chevronsDown}`} onClick={() => viewImages(item._id, item.aiimages)}/>
                            {item.aiimages.length > 0 ? (
                                <>
                                    {((item.aiimages.length > 4)?item.aiimages.slice(0, 4):item.aiimages).map((aiimg:string, aiIndex:number) => (
                                        <img key={"img_"+aiIndex} src={aiimg} alt='' className={css.uploadImage}/>
                                    ))}
                                </>
                            ):(
                                <>-</>
                            )}
                        </div>
                    )
                };
            });
           setBody(bodyData)
        }
    }

    useEffect(()=>{
        if(id){
            getUserData(id)
        }
    },[])

    const viewImages = (imageId:string, imgList:any) => {
        setImageList(imgList);
        setImageModal(true)
    }

    const redirectpage = (e:any) => {
        e.preventDefault();
        navigate(-1);
        return false;
    }

    return (
        <>
            <div className={`text-start ${css.userDetail}`}>
                <a onClick={redirectpage} className='user_view_link'><img alt="" src={arrowLeft}/></a>
                <Link to={"/users"} className='user_view_link'><span style={{opacity: "50%", marginLeft: '16px'}}>Users</span></Link> / {userData?userData.userid:""}
            </div>
            <div className={`card p-3 ${css.tableContainer}`}>
                <DatatableWrapper
                    body={body}
                    headers={headers}
                    paginationOptionsProps={{
                        initialState: {
                            rowsPerPage: 10,
                            options: [10, 20, 50, 100]
                        }
                }}>
                    <Table className={`request_table userDetailsTable ${css.imageTable}`}>
                        <TableHeader/>
                        <TableBody/>
                    </Table>
                    <Col xs={12} sm={6} lg={12} className="mt-3 d-flex justify-content-between align-items-center">
                        <div className="fw-bold" style={{padding: "0 12px"}}></div>
                        <div className={`d-flex gap-2 simplePagination paginationAction`} style={{padding: "0 12px 8px 12px"}}>
                            <Pagination />
                        </div>
                    </Col>
                </DatatableWrapper>
            </div>
            <Modal show={imageModal} onHide={closeImageModal} size='lg' aria-labelledby="contained-modal-title-vcenter" centered >
                <div className='modal_header d-flex justify-content-between align-items-center'>
                    <h4 className="text-center m-4">Upload Images</h4>
                    <button type='button' className='close-btn m-3' onClick={closeImageModal}>Close</button>
                </div>
                <div className="modal_body image-grid-view d-inline-flex p-3">
                    {imageList.map((img:string, imgIndex:number) => (
                        <img key={imgIndex} src={img} alt='' className={`${css.footerImage}`}/>
                    ))}
                </div>
            </Modal>
        </>
    )
}

export default UserDetail