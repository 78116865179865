import css from './index.module.css';
import Form from 'react-bootstrap/Form';
import {Col, Dropdown, DropdownButton, Modal, Row} from 'react-bootstrap';
import InputGroup from 'react-bootstrap/InputGroup';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCalendar, faMagnifyingGlass} from '@fortawesome/free-solid-svg-icons';
import { useEffect, useMemo, useState} from "react";
import apple from '../../assets/icons8-apple-96.png';
import android from '../../assets/icons8-android-96.png';
import API from '../../redux/services/Api';
import { useDispatch, useSelector } from 'react-redux';
import { ReducerStateIF } from '../../redux/reducers';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import DataTable from "react-data-table-component";
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { apply } from 'redux-saga/effects';

const User = () => {

    const userDetails = useSelector((state:ReducerStateIF) => state.app);
    // const [activeButton, setActiveButton] = useState('All');
    const dispatch:any = useDispatch();
    const [body, setBody] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [totalRows, setTotalRows] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [imageModal, setImageModal] = useState(false)
    const [dateModal, setDateModal] = useState(false)
    const [searchText, setSearchText] = useState("");
    const [searchData, setSerachData] = useState<any>({
        type:"",
        apply:false,
        selectedRange: null,
        startDate: moment().subtract(29, 'days'),
        endDate: moment(),
        counter: 0
    })
    
    const [dateFilter, setDateFilter] = useState<any>({
        apply: false,
        startDate: moment().subtract(29, 'days'),
        endDate: moment(),
        filterType: "",
        selectedRange: null,
        counter: 0,
        sorting: "desc",
        ranges: [
            { label: 'Today', start: moment(), end: moment() },
            { label: 'Yesterday', start: moment().subtract(1, 'days'), end: moment().subtract(1, 'days') },
            { label: 'Last 7 Days', start: moment().subtract(6, 'days'), end: moment() },
            { label: 'Last 30 Days', start: moment().subtract(29, 'days'), end: moment() },
            { label: 'This Month', start: moment().startOf('month'), end: moment().endOf('month') },
            { label: 'Last Month', start: moment().subtract(1, 'month').startOf('month'), end: moment().subtract(1, 'month').endOf('month') },
         ]
      })

    const handleActiveButton = (buttonName: string) => {
        setSerachData((prevState:any) => ({...prevState, type:buttonName}));
    };

    // const headers: TableColumnType<UserData>[] = [
    //     {title: 'Sr No', prop: 'index',isFilterable: true},
    //     {title: 'UserName/Device ID', prop: 'userid', isFilterable: true},
    //     {title: 'Status', prop: 'status', isFilterable: true},
    //     {title: 'Device', prop: 'devicetype', isFilterable: true},
    //     {title: 'History', prop: 'history', isFilterable: true},
    //     {title: 'Location', prop: 'location', isFilterable: true},
    //     {title: 'Live', prop: 'action'}
    // ];
    

    const columns = useMemo(
        () => [
          {
            name: "Sr No",
            cell: (row:any) => row.index
          },
          {
            name: "Created At",
            selector: (row:any) => row.createdAt
          },
          {
            name: "Location",
            selector: (row:any) => row.location
          },
          {
            name: "UserName/Device ID",
            selector: (row:any) => row.userid
          },
          {
            name: "Status",
            cell: (row:any) => row.status
          },
          {
            name: "Device",
            selector: (row:any) => row.devicetype
          },
          {
            name: (<><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_1506_6286)">
                        <path d="M10.0001 18.3333C14.6025 18.3333 18.3334 14.6024 18.3334 10C18.3334 5.39762 14.6025 1.66666 10.0001 1.66666C5.39771 1.66666 1.66675 5.39762 1.66675 10C1.66675 14.6024 5.39771 18.3333 10.0001 18.3333Z" stroke="black" strokeWidth="1.5" strokeLinejoin="round"/>
                        <path d="M10.0001 13.3333C11.841 13.3333 13.3334 11.8409 13.3334 10C13.3334 8.15905 11.841 6.66666 10.0001 6.66666C8.15913 6.66666 6.66675 8.15905 6.66675 10C6.66675 11.8409 8.15913 13.3333 10.0001 13.3333Z" stroke="black" strokeWidth="1.5" strokeLinejoin="round"/>
                        <path d="M10 6.66667H17.5M13.3333 10V17.5M6.66667 10V2.5M10 13.3333H2.5" stroke="black" strokeWidth="1.5" strokeLinecap="round"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_1506_6286">
                        <rect width="20" height="20" fill="white"/>
                        </clipPath>
                        </defs>
                        </svg> &nbsp; Solo</>      
            ),
            selector: (row:any) => row.solo
          },
          {
            name: (<><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.33325 9.16666V12.5C3.33325 15.2498 3.33325 16.6247 4.18753 17.4791C5.04179 18.3333 6.41673 18.3333 9.16659 18.3333H10.8333C13.5831 18.3333 14.958 18.3333 15.8123 17.4791C16.6666 16.6247 16.6666 15.2498 16.6666 12.5V9.16666" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M2.5 7.5C2.5 6.87693 2.5 6.56539 2.66747 6.33334C2.77717 6.18132 2.93497 6.05508 3.125 5.96731C3.41507 5.83334 3.80448 5.83334 4.58333 5.83334H15.4167C16.1955 5.83334 16.5849 5.83334 16.875 5.96731C17.065 6.05508 17.2228 6.18132 17.3325 6.33334C17.5 6.56539 17.5 6.87693 17.5 7.5C17.5 8.12308 17.5 8.43459 17.3325 8.66667C17.2228 8.81867 17.065 8.94492 16.875 9.03267C16.5849 9.16667 16.1955 9.16667 15.4167 9.16667H4.58333C3.80448 9.16667 3.41507 9.16667 3.125 9.03267C2.93497 8.94492 2.77717 8.81867 2.66747 8.66667C2.5 8.43459 2.5 8.12308 2.5 7.5Z" stroke="black" strokeWidth="1.5" strokeLinejoin="round"/>
            <path d="M8 14.0904L8.93933 15.1522C9.43933 15.7174 9.68933 16 10 16C10.3107 16 10.5607 15.7174 11.0607 15.1522L12 14.0904M10 15.9377V11" stroke="black" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M5 3.15476C5 2.33291 5.66624 1.66666 6.48809 1.66666H6.78572C8.56092 1.66666 10 3.10575 10 4.88095V5.83333H7.67857C6.19923 5.83333 5 4.63409 5 3.15476Z" stroke="black" strokeWidth="1.5" strokeLinejoin="round"/>
            <path d="M15 3.15476C15 2.33291 14.3338 1.66666 13.5119 1.66666H13.2142C11.4391 1.66666 10 3.10575 10 4.88095V5.83333H12.3214C13.8007 5.83333 15 4.63409 15 3.15476Z" stroke="black" strokeWidth="1.5" strokeLinejoin="round"/>
            </svg>
             &nbsp; Gift (<span>Free</span>&nbsp;|&nbsp;<span className='greenColor'>Paid</span>)</>      
            ),
            selector: (row:any) => row.gift
          },
          {
            name: (<><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 18.3333C9.31817 18.3333 8.66683 18.0538 7.36411 17.4949C4.12137 16.1036 2.5 15.4079 2.5 14.2378V6.45625M10 18.3333C10.6818 18.3333 11.3332 18.0538 12.6359 17.4949C15.8787 16.1036 17.5 15.4079 17.5 14.2378V6.45625M10 18.3333V10.1422M2.5 6.45625C2.5 6.95937 3.16797 7.27708 4.50393 7.9125L6.93827 9.07033C8.44067 9.78492 9.19192 10.1422 10 10.1422M2.5 6.45625C2.5 5.95313 3.16797 5.63542 4.50393 5M17.5 6.45625C17.5 6.95937 16.832 7.27708 15.4961 7.9125L13.0617 9.07033C11.5593 9.78492 10.8081 10.1422 10 10.1422M17.5 6.45625C17.5 5.95313 16.832 5.63542 15.4961 5M5.27669 11.0925L6.93826 11.8828" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M10.0001 1.66666V3.33333M13.3334 2.5L12.0834 4.16666M6.66675 2.5L7.91675 4.16666" stroke="black" strokeWidth="1.5" strokeLinecap="round"/>
            </svg>
             &nbsp; Explore </>      
            ),
            selector: (row:any) => row.explore
          },
          {
            name: "Live",
            selector: (row:any) => row.action
          }
        ],
        []
      );


    useEffect(() => {
        getUserData()
    },[searchData.type, currentPage, rowsPerPage, searchText]);
    

    const getUserData = async() => {
        setImageModal(false)
        setDateModal(false)
        let search:any = { page: currentPage, per_page: rowsPerPage, search_txt: searchText }
        if(searchData.type !== ""){
            search = {...search, type: searchData.type }
        }
        
        if(dateFilter.apply){
            search = {...search, filterFromDate: moment(dateFilter.startDate).format("YYYY-MM-DD"), filterToDate:moment(dateFilter.endDate).format("YYYY-MM-DD"), filterType: dateFilter.filterType, filterSort: dateFilter.sorting }
        }

        if(searchData.apply){
            search = {...search, fromDate: moment(searchData.startDate).format("YYYY-MM-DD"), toDate:moment(searchData.endDate).format("YYYY-MM-DD")}
        }

        setLoading(true)
        const response:any = await API.Post("admin/users", search, userDetails, dispatch)
        if(response.status === "success"){
            const tmpData = response.data.data;
            
            const bodyData = tmpData.map((itemData:any, index:number) => {
                
                const item = itemData.document;
                const statusButton = item.status;
                const device = item.devicetype === "android"?android:apple;
                var today = moment().utc();
                var yesterday = moment().utc().subtract(1, 'day');
                var createdAt = moment(item.createdAt).utc().format("hh:mm A, YYYY-MM-DD")
                
                if(moment(item.createdAt).utc().isSame(today, 'day')){
                    createdAt = moment(item.createdAt).format("hh:mm A, ")+' Today';
                }else if(moment(item.createdAt).utc().isSame(yesterday, 'day')){
                    createdAt = moment(item.createdAt).format("hh:mm A, ")+' Yesterday';
                }
                return {
                    index: "#"+((item.srno)?item.srno:0),
                    createdAt: createdAt,
                    location: item.location,
                    userid: (<Link className='user_view_link' to={"/users/"+item._id}>{item.userid}</Link>),
                    status: (<button type="button"
                                     className={`secondary rounded-5 border-0 py-1 px-3 ${statusButton === 'paid' ? css.paidButton : css.freeButton}`}>{statusButton}</button>),
                    devicetype: (<img alt="" src={device} width="32px"/>),
                    solo:( <ul className='userCounter'>
                                <li>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g opacity="0.5">
                                    <path d="M12.5 12.5L13.75 13.75" stroke="#222222" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M14.1159 15.8827C13.628 15.3949 13.628 14.6038 14.1159 14.1159C14.6039 13.628 15.3949 13.628 15.8828 14.1159L17.5508 15.7839C18.0387 16.2717 18.0387 17.0628 17.5508 17.5507C17.0629 18.0387 16.2718 18.0387 15.7839 17.5507L14.1159 15.8827Z" stroke="#222222" strokeWidth="1.5" strokeLinecap="round"/>
                                    <path d="M4.49426 2.98112L6.81788 3.7643C7.13915 3.87551 7.62666 3.80279 7.90271 3.60613L9.53275 2.44363C10.5757 1.70069 11.4388 2.14379 11.4398 3.43168L11.4487 5.61189C11.4502 5.98112 11.707 6.43931 12.0198 6.63839L13.6853 7.68258C15.0028 8.51 14.8529 9.48958 13.3515 9.87017L11.2618 10.3978C10.8843 10.493 10.4948 10.8826 10.3953 11.2646L9.86767 13.3547C9.4915 14.852 8.50334 15.0018 7.68051 13.6885L6.63651 12.0227C6.43746 11.7098 5.97936 11.453 5.6102 11.4515L3.4304 11.4425C2.14713 11.4372 1.69973 10.5782 2.44254 9.53517L3.60482 7.90483C3.79706 7.6331 3.86977 7.14549 3.75858 6.82417L2.97555 4.50012C2.55286 3.23687 3.23562 2.55397 4.49426 2.98112Z" stroke="#222222" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    </g>
                                    </svg>
                                    {itemData.soloCreate ?? 0}
                                </li>
                                <li>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g opacity="0.5">
                                    <path d="M17.9534 9.20417C18.2067 9.55942 18.3334 9.73708 18.3334 10C18.3334 10.2629 18.2067 10.4406 17.9534 10.7958C16.815 12.3922 13.9077 15.8333 10.0001 15.8333C6.0924 15.8333 3.18516 12.3922 2.04678 10.7958C1.79342 10.4406 1.66675 10.2629 1.66675 10C1.66675 9.73708 1.79342 9.55942 2.04678 9.20417C3.18516 7.60787 6.0924 4.16667 10.0001 4.16667C13.9077 4.16667 16.815 7.60787 17.9534 9.20417Z" stroke="#222222" strokeWidth="1.5"/>
                                    <path d="M12.5 10C12.5 8.61925 11.3807 7.5 10 7.5C8.61925 7.5 7.5 8.61925 7.5 10C7.5 11.3807 8.61925 12.5 10 12.5C11.3807 12.5 12.5 11.3807 12.5 10Z" stroke="#222222" strokeWidth="1.5"/>
                                    </g>
                                    </svg>
                                    {itemData.soloView.toString()}
                                </li>
                                <li>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g opacity="0.5">
                                    <path d="M10.0001 12.0833V3.75M10.0001 12.0833C9.41658 12.0833 8.32636 10.4214 7.91675 10M10.0001 12.0833C10.5836 12.0833 11.6738 10.4214 12.0834 10" stroke="#222222" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M16.6666 13.75C16.6666 15.8183 16.2349 16.25 14.1666 16.25H5.83325C3.76492 16.25 3.33325 15.8183 3.33325 13.75" stroke="#222222" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    </g>
                                    </svg>
                                    {itemData.soloDownload.toString()}
                                </li>
                            </ul>),
                    gift:( <ul className='userCounter'>
                        <li>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g opacity="0.5" clipPath="url(#clip0_1522_4739)">
                            <path d="M12.5 14.1667C11.8337 14.6853 10.9586 15 9.99998 15C9.04148 15 8.16628 14.6853 7.5 14.1667" stroke="#222222" strokeWidth="1.5" strokeLinecap="round"/>
                            <path d="M1.95957 11.0113C1.66539 9.09683 1.5183 8.13969 1.88022 7.29115C2.24214 6.4426 3.04511 5.86203 4.65103 4.70088L5.85091 3.83333C7.84866 2.38889 8.84757 1.66667 10.0001 1.66667C11.1526 1.66667 12.1515 2.38889 14.1492 3.83333L15.3491 4.70088C16.9551 5.86203 17.7581 6.4426 18.12 7.29115C18.4819 8.13969 18.3348 9.09683 18.0406 11.0113L17.7897 12.6437C17.3727 15.3574 17.1641 16.7143 16.1909 17.5238C15.2176 18.3333 13.7948 18.3333 10.9491 18.3333H9.05107C6.20537 18.3333 4.78251 18.3333 3.80925 17.5238C2.83599 16.7143 2.62747 15.3574 2.21044 12.6437L1.95957 11.0113Z" stroke="#222222" strokeWidth="1.5" strokeLinejoin="round"/>
                            </g>
                            <defs>
                            <clipPath id="clip0_1522_4739">
                            <rect width="20" height="20" fill="white"/>
                            </clipPath>
                            </defs>
                            </svg>
                            {itemData.giftHome ?? 0}
                        </li>
                        <li>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g opacity="0.5" clipPath="url(#clip0_1522_4744)">
                            <path d="M5 14.9788C5.10725 16.0691 5.34963 16.803 5.89742 17.3507C6.87997 18.3333 8.46133 18.3333 11.6241 18.3333C14.7868 18.3333 16.3682 18.3333 17.3507 17.3507C18.3333 16.3682 18.3333 14.7868 18.3333 11.6241C18.3333 8.46133 18.3333 6.87997 17.3507 5.89742C16.803 5.34963 16.0691 5.10725 14.9788 5" stroke="#222222" strokeWidth="1.5"/>
                            <path d="M1.66675 8.33333C1.66675 5.19063 1.66675 3.61929 2.64306 2.64298C3.61937 1.66667 5.19071 1.66667 8.33341 1.66667C11.4761 1.66667 13.0475 1.66667 14.0237 2.64298C15.0001 3.61929 15.0001 5.19063 15.0001 8.33333C15.0001 11.476 15.0001 13.0474 14.0237 14.0237C13.0475 15 11.4761 15 8.33341 15C5.19071 15 3.61937 15 2.64306 14.0237C1.66675 13.0474 1.66675 11.476 1.66675 8.33333Z" stroke="#222222" strokeWidth="1.5"/>
                            <path d="M4.16675 15C7.01726 11.0406 10.2207 5.78958 15.0001 9.72783" stroke="#222222" strokeWidth="1.5"/>
                            </g>
                            <defs>
                            <clipPath id="clip0_1522_4744">
                            <rect width="20" height="20" fill="white"/>
                            </clipPath>
                            </defs>
                            </svg>

                            {itemData.giftExample ?? 0}
                        </li>
                        <li>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g opacity="0.5">
                            <path d="M12.5 12.5L13.75 13.75" stroke="#222222" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M14.1159 15.8827C13.628 15.3949 13.628 14.6038 14.1159 14.1159C14.6039 13.628 15.3949 13.628 15.8828 14.1159L17.5508 15.7839C18.0387 16.2717 18.0387 17.0628 17.5508 17.5507C17.0629 18.0387 16.2718 18.0387 15.7839 17.5507L14.1159 15.8827Z" stroke="#222222" strokeWidth="1.5" strokeLinecap="round"/>
                            <path d="M4.49426 2.98112L6.81788 3.7643C7.13915 3.87551 7.62666 3.80279 7.90271 3.60613L9.53275 2.44363C10.5757 1.70069 11.4388 2.14379 11.4398 3.43168L11.4487 5.61189C11.4502 5.98112 11.707 6.43931 12.0198 6.63839L13.6853 7.68258C15.0028 8.51 14.8529 9.48958 13.3515 9.87017L11.2618 10.3978C10.8843 10.493 10.4948 10.8826 10.3953 11.2646L9.86767 13.3547C9.4915 14.852 8.50334 15.0018 7.68051 13.6885L6.63651 12.0227C6.43746 11.7098 5.97936 11.453 5.6102 11.4515L3.4304 11.4425C2.14713 11.4372 1.69973 10.5782 2.44254 9.53517L3.60482 7.90483C3.79706 7.6331 3.86977 7.14549 3.75858 6.82417L2.97555 4.50012C2.55286 3.23687 3.23562 2.55397 4.49426 2.98112Z" stroke="#222222" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </g>
                            </svg>
                            {itemData.giftGenerateBtn ?? 0} | <span className='greenColor'>{itemData.giftGenerateNow ?? 0}</span>
                        </li>
                        <li>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g opacity="0.5">
                            <path d="M17.9534 9.20417C18.2067 9.55942 18.3334 9.73708 18.3334 10C18.3334 10.2629 18.2067 10.4406 17.9534 10.7958C16.815 12.3922 13.9077 15.8333 10.0001 15.8333C6.0924 15.8333 3.18516 12.3922 2.04678 10.7958C1.79342 10.4406 1.66675 10.2629 1.66675 10C1.66675 9.73708 1.79342 9.55942 2.04678 9.20417C3.18516 7.60787 6.0924 4.16667 10.0001 4.16667C13.9077 4.16667 16.815 7.60787 17.9534 9.20417Z" stroke="#222222" strokeWidth="1.5"/>
                            <path d="M12.5 10C12.5 8.61925 11.3807 7.5 10 7.5C8.61925 7.5 7.5 8.61925 7.5 10C7.5 11.3807 8.61925 12.5 10 12.5C11.3807 12.5 12.5 11.3807 12.5 10Z" stroke="#222222" strokeWidth="1.5"/>
                            </g>
                            </svg>
                            {itemData.giftView ?? 0}
                        </li>
                        <li>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g opacity="0.5">
                            <path d="M10.0001 12.0833V3.75M10.0001 12.0833C9.41658 12.0833 8.32636 10.4214 7.91675 10M10.0001 12.0833C10.5836 12.0833 11.6738 10.4214 12.0834 10" stroke="#222222" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M16.6666 13.75C16.6666 15.8183 16.2349 16.25 14.1666 16.25H5.83325C3.76492 16.25 3.33325 15.8183 3.33325 13.75" stroke="#222222" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </g>
                            </svg>

                            {itemData.giftFreeUnlockView ?? 0} | <span className='greenColor'>{itemData.giftFreeDownload ?? 0}</span>
                        </li>
                        <li>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g opacity="0.5">
                            <path d="M10.0001 12.0833V3.75M10.0001 12.0833C9.41658 12.0833 8.32636 10.4214 7.91675 10M10.0001 12.0833C10.5836 12.0833 11.6738 10.4214 12.0834 10" stroke="#222222" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M16.6666 13.75C16.6666 15.8183 16.2349 16.25 14.1666 16.25H5.83325C3.76492 16.25 3.33325 15.8183 3.33325 13.75" stroke="#222222" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </g>
                            </svg>
                            <span className='greenColor'>{itemData.giftPaidPayment ?? 0} | {itemData.giftPaidDownload ?? 0}</span>
                        </li>
                    </ul>),
                    explore:( <ul className='userCounter'>
                        <li>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g opacity="0.5" clipPath="url(#clip0_1522_4951)">
                            <path d="M12.5 14.1667C11.8337 14.6853 10.9586 15 9.99998 15C9.04148 15 8.16628 14.6853 7.5 14.1667" stroke="#222222" strokeWidth="1.5" strokeLinecap="round"/>
                            <path d="M1.95957 11.0113C1.66539 9.09683 1.5183 8.13969 1.88022 7.29115C2.24214 6.4426 3.04511 5.86203 4.65103 4.70088L5.85091 3.83333C7.84866 2.38889 8.84757 1.66667 10.0001 1.66667C11.1526 1.66667 12.1515 2.38889 14.1492 3.83333L15.3491 4.70088C16.9551 5.86203 17.7581 6.4426 18.12 7.29115C18.4819 8.13969 18.3348 9.09683 18.0406 11.0113L17.7897 12.6437C17.3727 15.3574 17.1641 16.7143 16.1909 17.5238C15.2176 18.3333 13.7948 18.3333 10.9491 18.3333H9.05107C6.20537 18.3333 4.78251 18.3333 3.80925 17.5238C2.83599 16.7143 2.62747 15.3574 2.21044 12.6437L1.95957 11.0113Z" stroke="#222222" strokeWidth="1.5" strokeLinejoin="round"/>
                            </g>
                            <defs>
                            <clipPath id="clip0_1522_4951">
                            <rect width="20" height="20" fill="white"/>
                            </clipPath>
                            </defs>
                            </svg>
                            {itemData.exploreHome ?? 0}
                        </li>
                        <li>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g opacity="0.5">
                            <path d="M2.5 9.16667C2.5 6.80964 2.5 5.63113 3.23223 4.8989C3.96447 4.16667 5.14298 4.16667 7.5 4.16667H9.16667C11.5237 4.16667 12.7022 4.16667 13.4344 4.8989C14.1667 5.63113 14.1667 6.80964 14.1667 9.16667V13.3333C14.1667 15.6903 14.1667 16.8688 13.4344 17.6011C12.7022 18.3333 11.5237 18.3333 9.16667 18.3333H7.5C5.14298 18.3333 3.96447 18.3333 3.23223 17.6011C2.5 16.8688 2.5 15.6903 2.5 13.3333V9.16667Z" stroke="#222222" strokeWidth="1.5"/>
                            <path d="M14.1036 15.8333C15.0168 15.3228 15.3273 14.2005 15.9485 11.9557L16.827 8.78125C17.4483 6.53648 17.7588 5.41412 17.2316 4.52992C16.7043 3.64571 15.5452 3.34497 13.2269 2.7435L11.5876 2.31819C9.26925 1.71672 8.11013 1.41598 7.19694 1.92647C6.54678 2.28993 6.20207 2.96355 5.83325 4.13155" stroke="#222222" strokeWidth="1.5"/>
                            </g>
                            </svg>

                            {itemData.exploreView ?? 0}
                        </li>
                        <li>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g opacity="0.5">
                            <path d="M3.33341 8.33333C2.92571 8.3024 2.64112 8.23277 2.40746 8.07598C2.22548 7.95386 2.06923 7.79695 1.94763 7.61419C1.66675 7.19204 1.66675 6.60437 1.66675 5.42903C1.66675 4.25368 1.66675 3.66601 1.94763 3.24386C2.06923 3.0611 2.22548 2.90418 2.40746 2.78208C2.82784 2.5 3.41303 2.5 4.58341 2.5H15.4167C16.5872 2.5 17.1723 2.5 17.5927 2.78208C17.7747 2.90418 17.9309 3.0611 18.0525 3.24386C18.3334 3.66601 18.3334 4.25368 18.3334 5.42903C18.3334 6.50213 18.3334 7.08536 18.1197 7.5" stroke="#222222" strokeWidth="1.5" strokeLinecap="round"/>
                            <path d="M6.11096 6.17285C7.44838 4.73259 17.5083 8.26075 17.4999 9.54883C17.4905 11.0096 13.5713 11.4589 12.4849 11.7637C11.8317 11.947 11.6567 12.1348 11.5061 12.8198C10.8239 15.9222 10.4814 17.4652 9.70083 17.4997C8.45658 17.5547 4.80576 7.57841 6.11096 6.17285Z" stroke="#222222" strokeWidth="1.5"/>
                            </g>
                            </svg>

                            {itemData.exploreGenerateTap ?? 0}
                        </li>
                        <li>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g opacity="0.5">
                            <path d="M12.5 12.5L13.75 13.75" stroke="#222222" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M14.1159 15.8827C13.628 15.3949 13.628 14.6038 14.1159 14.1159C14.6039 13.628 15.3949 13.628 15.8828 14.1159L17.5508 15.7839C18.0387 16.2717 18.0387 17.0628 17.5508 17.5507C17.0629 18.0387 16.2718 18.0387 15.7839 17.5507L14.1159 15.8827Z" stroke="#222222" strokeWidth="1.5" strokeLinecap="round"/>
                            <path d="M4.49451 2.98112L6.81812 3.7643C7.13939 3.87551 7.62691 3.80279 7.90296 3.60613L9.533 2.44363C10.5759 1.70069 11.4391 2.14379 11.44 3.43168L11.4489 5.61189C11.4504 5.98112 11.7072 6.43931 12.0201 6.63839L13.6856 7.68258C15.003 8.51 14.8532 9.48958 13.3517 9.87017L11.2621 10.3978C10.8845 10.493 10.495 10.8826 10.3955 11.2646L9.86791 13.3547C9.49175 14.852 8.50358 15.0018 7.68076 13.6885L6.63676 12.0227C6.43771 11.7098 5.97961 11.453 5.61045 11.4515L3.43065 11.4425C2.14737 11.4372 1.69997 10.5782 2.44278 9.53517L3.60506 7.90483C3.79731 7.6331 3.87001 7.14549 3.75882 6.82417L2.9758 4.50012C2.55311 3.23687 3.23586 2.55397 4.49451 2.98112Z" stroke="#222222" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </g>
                            </svg>

                            {itemData.explorePurchase ?? 0}
                        </li>
                        <li>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g opacity="0.5">
                            <path d="M17.9532 9.20417C18.2065 9.55942 18.3332 9.73708 18.3332 10C18.3332 10.2629 18.2065 10.4406 17.9532 10.7958C16.8148 12.3922 13.9075 15.8333 9.99984 15.8333C6.09215 15.8333 3.18492 12.3922 2.04654 10.7958C1.79318 10.4406 1.6665 10.2629 1.6665 10C1.6665 9.73708 1.79318 9.55942 2.04654 9.20417C3.18492 7.60787 6.09215 4.16667 9.99984 4.16667C13.9075 4.16667 16.8148 7.60787 17.9532 9.20417Z" stroke="#222222" strokeWidth="1.5"/>
                            <path d="M12.5 10C12.5 8.61925 11.3807 7.5 10 7.5C8.61925 7.5 7.5 8.61925 7.5 10C7.5 11.3807 8.61925 12.5 10 12.5C11.3807 12.5 12.5 11.3807 12.5 10Z" stroke="#222222" strokeWidth="1.5"/>
                            </g>
                            </svg>

                            {itemData.exploreGeneratedImgView ?? 0}
                        </li>
                        <li>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g opacity="0.5">
                            <path d="M9.99984 12.0833V3.75M9.99984 12.0833C9.41634 12.0833 8.32611 10.4214 7.9165 10M9.99984 12.0833C10.5833 12.0833 11.6736 10.4214 12.0832 10" stroke="#222222" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M16.6668 13.75C16.6668 15.8183 16.2352 16.25 14.1668 16.25H5.8335C3.76516 16.25 3.3335 15.8183 3.3335 13.75" stroke="#222222" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </g>
                            </svg>
                            {itemData.exploreDownload ?? 0}
                        </li>
                    </ul>),
                    action: (
                        <div className={css.toggleAction}><Form>
                            <Form.Check type="switch" id="disabled-custom-switch" onChange={(e) => changeUserStatus(e, item.userid )} checked={item.isLive === "active"}/></Form>
                        </div>),
                };
            });
            setBody(bodyData)
            setTotalRows(response.data.total)
        }
        setLoading(false)
    }

    const changeUserStatus = async(e:any, userid:string) => {
        try {
            const response:any = await API.Post("admin/user-live-status", {userId: userid}, userDetails, dispatch)
            if(response.status === "success"){
                getUserData();
                toast.success(response.message)
            }            
        } catch (error) {
            console.log(error)
        }
    }

    const handlePageChange = (page:number) => {
        setCurrentPage(page);
    };

    const handleRowsPerPageChange = async (newRowsPerPage:number) => {
        if (!body.length) return;
        setRowsPerPage(newRowsPerPage);
        setCurrentPage(1);
    };

    const searchTxt = (e:any) => {
        setSearchText(e.target.value)
    }

    const openImageModal = () => {
        setImageModal(true)
    }

    const closeImageModal = () => {
        setImageModal(false)
    }

    const handleSelectRange = (range: { start: Date; end: Date }, index:number) => {
        setDateFilter((oldState:any) => ({
            ...oldState,
            startDate: moment(range.start),
            endDate: moment(range.end),
            selectedRange: index
        }))
      };

    const selectSortType = (type:string) => {
        const filterType = (type == dateFilter.filterType)?"":type
        setDateFilter((oldState:any) => ({
            ...oldState,
            filterType: filterType
        }))
    }

    const selectSorting = (sort:string) => {
        setDateFilter((oldState:any) => ({
            ...oldState,
            sorting: sort
        }))
    }

    const applyFilter = () => {
        setDateFilter((oldState:any) => ({
            ...oldState,
            apply: true,
            counter: (oldState.counter+1)
        }))
    }
    const resetFilter = () => {
        setDateFilter((oldState:any) => ({
            ...oldState,
            apply: false,
            filterType: "",
            selectedRange: null,
            sorting: "desc",
            counter: (oldState.counter+1)
        }))
    }

    useEffect(() => {
        getUserData()
    },[dateFilter.counter, searchData.counter])
 
    const openDateModal = () => {
        setDateModal(true)
    }

    const closeDateImageModal = () => {
        setDateModal(false)
    }

    const handleSearchSelectRange = (range: { start: Date; end: Date }, index:number) => {
        setSerachData((oldState:any) => ({
            ...oldState,
            startDate: moment(range.start),
            endDate: moment(range.end),
            selectedRange: index
        }))
      };

    const applySearchDateFilter = () => {
        setSerachData((oldState:any) => ({
            ...oldState,
            apply: true,
            counter: (oldState.counter+1)
        }))
    }
    const resetSearchDateFilter = () => {
        setSerachData((oldState:any) => ({
            ...oldState,
            apply: false,
            selectedRange: null,
            counter: (oldState.counter+1)
        }))
    }

    return (
        <div className={`card p-3 ${css.tableContainer}`}>
                <Row className="mb-4 p-2">
                    <Col xs={12} sm={12} md={4} lg={3}
                         className="d-flex flex-col align-items-center justify-content-between gap-3">
                        <div className={`btn-group ${css.tableButtons}`} role="group">
                            <button type="button" onClick={() => handleActiveButton('')}
                                    style={{borderRadius: '50px 0 0 50px'}}
                                    className={searchData.type === '' ? css.activeButton : css.allUserFilterButtons}>All Users
                            </button>
                            <button type="button" onClick={() => handleActiveButton('free')}
                                    className={searchData.type === 'free' ? css.activeButton : css.freeFilterButtons}>Free
                            </button>
                            <button type="button" onClick={() => handleActiveButton('paid')}
                                    style={{borderRadius: '0 50px 50px 0'}}
                                    className={searchData.type === 'paid' ? css.activeButton : css.paidFilterButtons}>Paid
                            </button>
                        </div>
                    </Col>
                    <Col xs={6} sm={6} md={4} lg={6} className="d-flex search-input">
                        <InputGroup className={` m-2 ${css.searchInput}`}>
                            <InputGroup.Text id="basic-addon1">
                                <FontAwesomeIcon icon={faMagnifyingGlass}/>
                            </InputGroup.Text>
                            <input name="table-filter" value={searchText} onChange={searchTxt} placeholder="Enter text..." type="text" className="form-control" />
                        </InputGroup>
                    </Col>
                    <Col xs={6} sm={6} md={4} lg={3} className='d-flex align-item-center justify-content-end'>
                        <button type='button' className='transparentBtn calenderBtn' onClick={openDateModal}>
                            <FontAwesomeIcon icon={faCalendar}></FontAwesomeIcon>
                        </button>
                        <button type='button' className='transparentBtn' onClick={openImageModal}>
                            <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="1" y="1" width="42" height="42" rx="21" stroke="#C9C9C9" strokeWidth="2"/>
                            <path d="M26 23.3334C26 23.3334 23.054 26.6667 22 26.6667C20.9459 26.6667 18 23.3333 18 23.3333M26 20.6666C26 20.6666 23.054 17.3333 22 17.3333C20.9459 17.3333 18 20.6667 18 20.6667" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </button>
                    </Col>
                </Row>

                <DataTable
                        className='user_tables_style'
                        pagination
                        paginationServer
                        // selectableRows
                        columns={columns}
                        data={body}
                        progressPending={loading}
                        paginationTotalRows={totalRows}
                        selectableRowsNoSelectAll={false}
                        paginationDefaultPage={currentPage}
                        paginationRowsPerPageOptions={[10,20,50,100]}
                        paginationPerPage={rowsPerPage}
                        // onRowMouseEnter={handleMouseEnter}
                        // onRowMouseLeave={handleMouseLeave}
                        onChangePage={handlePageChange}
                        onChangeRowsPerPage={handleRowsPerPageChange}
                        // onSelectedRowsChange={handleOnSelectedRowsChange}
                        // selectableRowSelected={handleApplySelectedRows}
                    />
                
               
            {/* </DatatableWrapper> */}
            <Modal show={imageModal} onHide={closeImageModal} size='lg' aria-labelledby="contained-modal-title-vcenter" centered >
                <div className='modal_header d-flex justify-content-between align-items-center'>
                    <h4 className="text-center m-4">Filter</h4>
                    <button type='button' className='close-btn m-3' onClick={closeImageModal}>Close</button>
                </div>
                <div className="modal_body image-grid-view p-3">
                    <Row>
                        <Col sm={6} className='filterTypes'>
                            <h6><strong>Solo</strong></h6>
                            <ul>
                                <li onClick={() => selectSortType("soloCreate")}>
                                    Generate 
                                    {dateFilter.filterType == "soloCreate" && (
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3.3335 9.66667C3.3335 9.66667 4.3335 9.66667 5.66683 12C5.66683 12 9.37271 5.88889 12.6668 4.66667" stroke="#475467" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>                                        
                                    )}
                                </li>
                                <li onClick={() => selectSortType("soloView")}>Result View
                                    {dateFilter.filterType == "soloView" && (
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3.3335 9.66667C3.3335 9.66667 4.3335 9.66667 5.66683 12C5.66683 12 9.37271 5.88889 12.6668 4.66667" stroke="#475467" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>                                        
                                    )}
                                </li>
                                <li onClick={() => selectSortType("soloDownload")}>Download
                                    {dateFilter.filterType == "soloDownload" && (
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3.3335 9.66667C3.3335 9.66667 4.3335 9.66667 5.66683 12C5.66683 12 9.37271 5.88889 12.6668 4.66667" stroke="#475467" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>                                        
                                    )}
                                </li>
                            </ul>
                            <hr/>
                            <h6><strong>Gift</strong></h6>
                            <ul>
                                <li onClick={() => selectSortType("giftHome")}>Gift Home Screen
                                    {dateFilter.filterType == "giftHome" && (
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3.3335 9.66667C3.3335 9.66667 4.3335 9.66667 5.66683 12C5.66683 12 9.37271 5.88889 12.6668 4.66667" stroke="#475467" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>                                        
                                    )}
                                </li>
                                <li onClick={() => selectSortType("giftExample")}>Example Screen
                                    {dateFilter.filterType == "giftExample" && (
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3.3335 9.66667C3.3335 9.66667 4.3335 9.66667 5.66683 12C5.66683 12 9.37271 5.88889 12.6668 4.66667" stroke="#475467" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>                                        
                                    )}
                                </li>
                                <li onClick={() => selectSortType("giftGenerateBtn")}>Generate Tap
                                    {dateFilter.filterType == "giftGenerateBtn" && (
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3.3335 9.66667C3.3335 9.66667 4.3335 9.66667 5.66683 12C5.66683 12 9.37271 5.88889 12.6668 4.66667" stroke="#475467" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>                                        
                                    )}
                                </li>
                                <li onClick={() => selectSortType("giftGenerateNow")}>Generate Uploaded
                                    {dateFilter.filterType == "giftGenerateNow" && (
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3.3335 9.66667C3.3335 9.66667 4.3335 9.66667 5.66683 12C5.66683 12 9.37271 5.88889 12.6668 4.66667" stroke="#475467" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>                                        
                                    )}
                                </li>
                                <li onClick={() => selectSortType("giftView")}>Result View
                                    {dateFilter.filterType == "giftView" && (
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3.3335 9.66667C3.3335 9.66667 4.3335 9.66667 5.66683 12C5.66683 12 9.37271 5.88889 12.6668 4.66667" stroke="#475467" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>                                        
                                    )}
                                </li>
                                <li onClick={() => selectSortType("giftFreeUnlockView")}>Free Unlock View
                                    {dateFilter.filterType == "giftFreeUnlockView" && (
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3.3335 9.66667C3.3335 9.66667 4.3335 9.66667 5.66683 12C5.66683 12 9.37271 5.88889 12.6668 4.66667" stroke="#475467" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>                                        
                                    )}
                                </li>
                                <li onClick={() => selectSortType("giftFreeDownload")}>Free Download
                                    {dateFilter.filterType == "giftFreeDownload" && (
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3.3335 9.66667C3.3335 9.66667 4.3335 9.66667 5.66683 12C5.66683 12 9.37271 5.88889 12.6668 4.66667" stroke="#475467" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>                                        
                                    )}
                                </li>
                                <li onClick={() => selectSortType("giftPaidPayment")}>Payment Done
                                    {dateFilter.filterType == "giftPaidPayment" && (
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3.3335 9.66667C3.3335 9.66667 4.3335 9.66667 5.66683 12C5.66683 12 9.37271 5.88889 12.6668 4.66667" stroke="#475467" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>                                        
                                    )}
                                </li>
                                <li onClick={() => selectSortType("giftPaidDownload")}>Paid Download
                                    {dateFilter.filterType == "giftPaidDownload" && (
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3.3335 9.66667C3.3335 9.66667 4.3335 9.66667 5.66683 12C5.66683 12 9.37271 5.88889 12.6668 4.66667" stroke="#475467" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>                                        
                                    )}
                                </li>
                            </ul>
                            <hr/>
                            <h6><strong>Explore Pack</strong></h6>
                            <ul>
                                <li onClick={() => selectSortType("exploreHome")}>Explore Screen View
                                    {dateFilter.filterType == "exploreHome" && (
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3.3335 9.66667C3.3335 9.66667 4.3335 9.66667 5.66683 12C5.66683 12 9.37271 5.88889 12.6668 4.66667" stroke="#475467" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>                                        
                                    )}
                                </li>
                                <li onClick={() => selectSortType("exploreView")}>View Pack
                                    {dateFilter.filterType == "exploreView" && (
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3.3335 9.66667C3.3335 9.66667 4.3335 9.66667 5.66683 12C5.66683 12 9.37271 5.88889 12.6668 4.66667" stroke="#475467" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>                                        
                                    )}
                                </li>
                                <li onClick={() => selectSortType("exploreGenerateTap")}>Tap on Generate
                                    {dateFilter.filterType == "exploreGenerateTap" && (
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3.3335 9.66667C3.3335 9.66667 4.3335 9.66667 5.66683 12C5.66683 12 9.37271 5.88889 12.6668 4.66667" stroke="#475467" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>                                        
                                    )}
                                </li>
                                <li onClick={() => selectSortType("explorePurchase")}>Paid Generate
                                    {dateFilter.filterType == "explorePurchase" && (
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3.3335 9.66667C3.3335 9.66667 4.3335 9.66667 5.66683 12C5.66683 12 9.37271 5.88889 12.6668 4.66667" stroke="#475467" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>                                        
                                    )}
                                </li>
                                <li onClick={() => selectSortType("exploreGeneratedImgView")}>Result View
                                    {dateFilter.filterType == "exploreGeneratedImgView" && (
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3.3335 9.66667C3.3335 9.66667 4.3335 9.66667 5.66683 12C5.66683 12 9.37271 5.88889 12.6668 4.66667" stroke="#475467" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>                                        
                                    )}
                                </li>
                                <li onClick={() => selectSortType("exploreDownload")}>Download
                                    {dateFilter.filterType == "exploreDownload" && (
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3.3335 9.66667C3.3335 9.66667 4.3335 9.66667 5.66683 12C5.66683 12 9.37271 5.88889 12.6668 4.66667" stroke="#475467" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>                                        
                                    )}
                                </li>
                            </ul>
                        </Col>
                        <Col sm={6}>
                                <Row>
                                    <Col sm={12}>
                                        <ul className='preDates'>
                                            {dateFilter.ranges.map((range:any, index:number) => (
                                                <li className={'datebox '+((dateFilter.selectedRange != null && (Number(dateFilter.selectedRange) == index))?"selectedBox":"")} key={index} onClick={() => handleSelectRange(range, index)}>
                                                    {range.label}
                                                </li>
                                            ))}
                                        </ul>
                                    </Col>
                                    
                                    <Col sm={12}>
                                        <br/>
                                        <Form.Group controlId="formDateRange">
                                            <div className="d-flex">
                                                <div className='m-1'>
                                                    <Form.Label>Start Date</Form.Label>
                                                    <DatePicker
                                                        selected={dateFilter.startDate.toDate()}
                                                        onChange={(date: Date) => setDateFilter((oldRecord:any) => ({...oldRecord, startDate: moment(date), selectedRange: null}))}
                                                        selectsStart
                                                        startDate={dateFilter.startDate.toDate()}
                                                        endDate={dateFilter.endDate.toDate()}
                                                        className="form-control"
                                                        dateFormat="dd/MM/YYYY"
                                                    />
                                                </div>
                                                <div className='m-1'>
                                                    <Form.Label>End Date</Form.Label>
                                                    <DatePicker
                                                        selected={dateFilter.endDate.toDate()}
                                                        onChange={(date: Date) => setDateFilter((oldRecord:any) => ({...oldRecord, endDate: moment(date),selectedRange: null}))}
                                                        selectsEnd
                                                        startDate={dateFilter.startDate.toDate()}
                                                        endDate={dateFilter.endDate.toDate()}
                                                        className="form-control"
                                                        dateFormat="dd/MM/YYYY"
                                                    />
                                                </div>
                                            </div>
                                        </Form.Group>
                                        <hr/>
                                        <div className='filterTypes'>
                                            <ul className='radioButtons p-0'>
                                                <li>
                                                    <label>
                                                        <input type='radio' name='sorting' checked={(dateFilter.sorting == "asc")} value={"asc"} onChange={() => selectSorting("asc")}/>
                                                         &nbsp; Ascending
                                                    </label>
                                                </li>
                                                <li>
                                                    <label>
                                                        <input type='radio' name='sorting' checked={(dateFilter.sorting == "desc")} value={"desc"} onChange={() => selectSorting("desc")}/>
                                                        &nbsp; Descending
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                        <hr/>
                                        <div className='d-flex gap-3 justify-content-end'>
                                            <button type="button" className="Examples_actionButtonCancel__VaAz- btn btn-success" onClick={resetFilter}>Reset</button>
                                            <button type="button" className="Examples_actionButtonSave__rY3PT btn btn-success" onClick={applyFilter}>Apply</button>
                                        </div>
                                    </Col>
                                </Row>
                        </Col>
                    </Row>
                </div>
            </Modal>

            {/* </DatatableWrapper> */}
            <Modal show={dateModal} onHide={closeDateImageModal} size='lg' aria-labelledby="contained-modal-title-vcenter" centered >
                <div className='modal_header d-flex justify-content-between align-items-center'>
                    <h4 className="text-center m-4">Date Filter</h4>
                    <button type='button' className='close-btn m-3' onClick={closeDateImageModal}>Close</button>
                </div>
                <div className="modal_body image-grid-view p-3">
                    <Row>
                        <Col sm={12}>
                            <ul className='preDates'>
                                {dateFilter.ranges.map((range:any, index:number) => (
                                    <li className={'datebox '+((searchData.selectedRange != null && (Number(searchData.selectedRange) == index))?"selectedBox":"")} key={index} onClick={() => handleSearchSelectRange(range, index)}>
                                        {range.label}
                                    </li>
                                ))}
                            </ul>
                        </Col>
                        <Col sm={12}>
                            <br/>
                            <Form.Group controlId="formDateRange">
                                <div className="d-flex">
                                    <div className='m-1'>
                                        <Form.Label>Start Date</Form.Label>
                                        <br/>
                                        <DatePicker
                                            selected={searchData.startDate.toDate()}
                                            onChange={(date: Date) => setSerachData((oldRecord:any) => ({...oldRecord, startDate: moment(date), selectedRange: null}))}
                                            selectsStart
                                            startDate={searchData.startDate.toDate()}
                                            endDate={searchData.endDate.toDate()}
                                            className="form-control"
                                            dateFormat="dd/MM/YYYY"
                                        />
                                    </div>
                                    <div className='m-1'>
                                        <Form.Label>End Date</Form.Label>
                                        <br/>
                                        <DatePicker
                                            selected={searchData.endDate.toDate()}
                                            onChange={(date: Date) => setSerachData((oldRecord:any) => ({...oldRecord, endDate: moment(date),selectedRange: null}))}
                                            selectsEnd
                                            startDate={searchData.startDate.toDate()}
                                            endDate={searchData.endDate.toDate()}
                                            className="form-control"
                                            dateFormat="dd/MM/YYYY"
                                        />
                                    </div>
                                </div>
                            </Form.Group>
                            <hr/>
                            <div className='d-flex gap-3 justify-content-end'>
                                <button type="button" className="Examples_actionButtonCancel__VaAz- btn btn-success" onClick={resetSearchDateFilter}>Reset</button>
                                <button type="button" className="Examples_actionButtonSave__rY3PT btn btn-success" onClick={applySearchDateFilter}>Apply</button>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Modal>
        </div>
    )
}

export default User