import css from './index.module.css'
import {Col, Modal, Row} from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose, faMagnifyingGlass, faRefresh} from "@fortawesome/free-solid-svg-icons";
import downArrow from '../../assets/download.svg';
import upArrow from '../../assets/upload.svg';
import submit from '../../assets/submit.svg';
import chevronsDown from '../../assets/chevrons-down.svg'
import {useCallback, useEffect, useMemo, useState} from "react";
import android from "../../assets/icons8-android-96.png";
import apple from "../../assets/icons8-apple-96.png";
import API from '../../redux/services/Api';
import { useDispatch, useSelector } from 'react-redux';
import { ReducerStateIF } from '../../redux/reducers';
import moment from 'moment';
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import { Link } from 'react-router-dom';

const Requests = () => {

    const userDetails = useSelector((state:ReducerStateIF) => state.app);
    const dispatch:any = useDispatch();
    const [imageModal, setImageModal] = useState(false)
    const [body, setBody] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchText, setSearchText] = useState("");
    const [searchData, setSerachData] = useState({
        type:""
    })
    const [showRejectBtn, setShowRejectBtn] = useState(false);
    const [uploadModal, setUploadModal] = useState(false);
    const initEditData = {
        imageId: null,
        index: null,
        imageList: []
    }
    const [editData, setEditData] = useState<any>(initEditData)
    // #1C5E3C

    const initCount = {
            all: 0,
            free: 0,
            paid: 0
    }
    const [totalCount, setTotalCount] = useState<any>(initCount)

    
    const handleActiveButton = (buttonName: string) => {
        setSerachData(prevState => ({...prevState, type:buttonName}));
    };

    const columns = useMemo(
        () => [
          {
            name: "Time & Date",
            cell: (row:any) => row.timedate
          },
          {
            name: "Status",
            selector: (row:any) => row.status
          },
          {
            name: "Device",
            cell: (row:any) => row.device
          },
          {
            name: "Device Id",
            cell: (row:any) => row.deviceId
          },
          {
            name: "Location",
            cell: (row:any) => row.location
          },
          {
            name: "Sr No.",
            selector: (row:any) => row.srno
          },
          {
            name: "Images by Users",
            selector: (row:any) => row.userimg
          },
          {
            name: "Upload/Download",
            selector: (row:any) => row.uploaddownload
          },
          {
            name: "Ai Generated",
            selector: (row:any) => row.aiimage
          },
          {
            name: "Live",
            selector: (row:any) => row.action
          }
        ],
        []
      );

    useEffect(() => {
        getUserData()
    },[searchData.type, currentPage, rowsPerPage, searchText]);

    const [checkedRequest, setCheckedRequest] = useState<any>([]);

    const handleOnSelectedRowsChange = useCallback(
        ({ selectedRows }:any) => {
            if(selectedRows.length > 0){
                const bodayData = selectedRows.map((el:any) => el._id)
                setCheckedRequest((oldState:any) => ([...bodayData]))
            }else{
                setCheckedRequest([])
            }
            return;
        },
        [currentPage]
    );

    useEffect(() => {
        if(checkedRequest.length > 0){
            setShowRejectBtn(true)
        }else{
            setShowRejectBtn(false)
        }
    },[checkedRequest])

    const getUserData = async() => {

        let search:any = { page: currentPage, per_page: rowsPerPage, search_txt: searchText }
        if(searchData.type !== ""){
            search = {...search,  type: searchData.type }
        }

        const response:any = await API.Post("admin/requests", search, userDetails, dispatch)
        if(response.status === "success"){
            const tmpData = response.data.data;
            const bodyData = tmpData.map((item:any, index:number) => {
                
                const statusButton = item.userId.status;
                const downloadButton = !item.downloaded ? 'Download' : 'Upload';
                const isSubmitDisable = item.aiimages.length === 0;
                const device = item.userId.devicetype === "android"?android:apple;
                const deviceID = item.userId.userid;
                const uploadImages = item.images
                var today = moment();
                var yesterday = moment().subtract(1, 'day');
                var createdAt = moment(item.createdAt).format("hh:mm A, YYYY-MM-DD")
                
                if(moment(item.createdAt).isSame(today, 'day')){
                    createdAt = moment(item.createdAt).format("hh:mm A, ")+' Today';
                }else if(moment(item.createdAt).isSame(yesterday, 'day')){
                    createdAt = moment(item.createdAt).format("hh:mm A, ")+' Yesterday';
                }


                return {
                    _id: item._id,
                    timedate: createdAt,
                    status: (<button type="button" className={`secondary rounded-5 border-0 py-1 px-3 ${statusButton === 'paid' ? css.paidButton : css.freeButton}`}>{statusButton}</button>),
                    device: (<img alt="" src={device} width="32px"/>),
                    deviceId: (<Link className='user_view_link' to={"/users/"+item.userId._id}>{deviceID}</Link>),
                    location: item.userId.location,
                    srno: "#"+((item.srno)?item.srno:0),
                    userimg: (
                        <div>
                            {((item.images.length > 4)?item.images.slice(0, 4):item.images).map((img:any, imgIndex:number) => (
                                <img key={"img_"+imgIndex} src={img} alt='' className={css.uploadImage} loading="lazy"/>
                            ))}
                        </div>
                    ),
                    uploaddownload: (downloadButton === 'Download')?(<div className="d-flex">
                                            <button type="button" className={`secondary border-0 py-2 px-3 ${css.downloadButton}`} onClick={() => downloadImages(("#"+((item.srno)?item.srno:0)),item._id, uploadImages)}>
                                                <img alt='' src={downArrow} className="me-2"/>{downloadButton}
                                            </button>
                                        </div>
                                    ):(
                                        <div className="d-flex">
                                            <button type="button" className={`secondary border-0 py-2 px-3 ${css.uploadButton}`} onClick={() => openUploadModal(item._id)}>
                                                <img alt='' src={upArrow}
                                                    className="me-2"/>{downloadButton}
                                            </button>
                                            <img alt='' src={downArrow} className={`${css.downArrow}`} onClick={() => downloadImages(("#"+((item.srno)?item.srno:0)), item._id, uploadImages)}/>
                                        </div>
                    ),
                    aiimage: (item.aiimages && item.aiimages.length > 0) ? (
                        <div className="d-flex align-items-center">
                            <img alt="" src={chevronsDown} className={`p-2 me-2 ${css.chevronsDown}`} onClick={() => viewImages(item._id,item.aiimages)}/>
                            {((item.aiimages.length > 4)?item.aiimages.slice(0, 4):item.aiimages).map((aiimg:string, aiIndex:number) => (
                                <img key={"img_"+aiIndex} src={aiimg} alt='' className={css.uploadImage} loading="lazy"/>
                            ))}
                        </div>
                    ) : '-',
                    action: (<button type="button" disabled={isSubmitDisable} className={`secondary rounded-3 border-0 py-2 px-2 ${isSubmitDisable ? css.issubmitDisable : css.submitButton}`} onClick={() => {
                        if(!isSubmitDisable){
                            submitImages(item._id)
                        }
                    }}>
                        <img alt='' src={submit} className="me-2"/>Submit
                    </button>),
                }

            });
           setBody(bodyData)
        //    setTotalRows(response.data.total)
           if(response.data.total.length > 0){
                delete response.data.total[0]._id;
                setTotalCount(response.data.total[0])
            }else{
                setTotalCount(initCount)
            }
        }
    }

    const handlePageChange = (page:number) => {
        setCurrentPage(page);
    };

    const handleRowsPerPageChange = async (newRowsPerPage:number) => {
        if (!body.length) return;
        setRowsPerPage(newRowsPerPage);
        setCurrentPage(1);
    };

    const searchTxt = (e:any) => {
        setSearchText(e.target.value)
    }
    

    const downloadImages = async(downloadName:string, id:string, urls:any) => {
        try {
            setLoading(true)
            const responseDownload = await axios.post(process.env.REACT_APP_API_URI + "admin/download-images", {
                    files: urls,
                    filename: downloadName
                },
                {responseType: 'blob'}
            ); 
            if(responseDownload){
                const response:any = await API.Post("admin/user-images-downloaded", {imageId: id}, userDetails, dispatch)
                if(response.status === "success"){
                    
                    const url = window.URL.createObjectURL(new Blob([responseDownload.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', downloadName+'.zip');
                    document.body.appendChild(link);
                    link.click();

                    getUserData();
                    setLoading(false)
                }else{
                    setLoading(false)
                }
            }else{
                setLoading(false)
            }
        } catch (error) {
            console.log(error)
            setLoading(false)
        }
    };

    const openUploadModal = async(id:string, index?:number) => {
        if(index){
            if(index > 0){
                setEditData((prevState: any) => ({...prevState, imageId:id, index:(index).toString()}));
            }else{
                setEditData((prevState: any) => ({...prevState, imageId:id, index:null}));
            }
        }else{
            if(index === 0){
                setEditData((prevState: any) => ({...prevState, imageId:id, index:(index).toString()}));
            }else{
                setEditData((prevState: any) => ({...prevState, imageId:id, index:null}));
            }
        }
        setUploadModal(true)
    }

    const closeUploadModal = async() => {
        setEditData((prevState: any) => ({...prevState, index:null}));
        setUploadModal(false)
    }

    const uploadAiImages = async(e:any) => {
        e.preventDefault()
        try {
            let form = new FormData(e.target)
                form.append('imageId', editData.imageId)
                if(editData.index !== null){
                    form.append('index', (editData.index).toString())
                }
            
                const response:any = await API.Post("admin/upload-userai-images", form, userDetails, dispatch, true)
                if(response.status === "success"){
                    toast.success(response.message)
                    e.target.reset()
                    if(editData.index !== null){
                        setEditData((prevState: any) => ({...prevState, index:null, imageList: response.data}));
                    }else{
                        setEditData((prevData: any) => (initEditData))
                    }
                    getUserData()
                    closeUploadModal()
                }else{
                    toast.error(response.message)
                }

        } catch (error) {
            console.log(error)
        }
    }

    const viewImages = (imageId:string, imgList:any) => {
        setEditData((prevState: any) => ({...prevState, imageId:imageId, imageList: imgList}));
        setImageModal(true)
    }

    const deleteAiImages = async(index:number) => {
        try {
            const response:any = await API.Post("admin/delete-userai-image", {imageId: editData.imageId, index:index}, userDetails, dispatch)
            if(response.status === "success"){
                toast.success(response.message)
                let tmpList = editData.imageList;
                tmpList.splice(index, 1);
                getUserData()
                setEditData((prevData: any) => ({
                    ...prevData,
                    imageList: tmpList
                }))
            }else{
                toast.error(response.message)
            }

        } catch (error) {
            console.log(error)
        }
    }

    const submitImages = async (imageId:string) => {
        try {
            const response:any = await API.Post("admin/user-images-status", {imageId: imageId}, userDetails, dispatch)
            if(response.status === "success"){
                toast.success(response.message)
                getUserData()
            }else if(response.status === "warning"){
                    toast.warning(response.message)
                    getUserData()
            }else{
                toast.error(response.message)
            }

        } catch (error) {
            console.log(error)
        }
    }

    const closeImageModal = () => {
        setImageModal(false)
        setEditData(initEditData);
    }

    const rejectRequests = async() => {
        try {
            const response:any = await API.Post("admin/reject-user-images", {imageIds: checkedRequest}, userDetails, dispatch)
            if(response.status === "success"){
                toast.success(response.message)
                setCheckedRequest([])
                getUserData()
            }else{
                toast.error(response.message)
            }

        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>
            <div className={`card p-3 ${css.tableContainer}`}>
                {/* <DatatableWrapper
                    body={body}
                    headers={headers}
                    paginationOptionsProps={{
                        initialState: {
                            rowsPerPage: 10,
                            options: [10, 20, 50, 100]
                        }
                    }}
                > */}
                    <Row className="mb-4 p-2">
                        <Col xs={12} sm={6} md={4} lg={4}
                            className="d-flex flex-col align-items-center justify-content-between gap-3">
                            <div className={`btn-group ${css.tableButtons}`} role="group">
                                <button type="button"
                                        className={`d-flex flex-col align-items-center gap-2 ${searchData.type === '' ? css.activeButton : css.allUserFilterButtons}`}
                                        onClick={() => handleActiveButton('')}
                                        style={{borderRadius: '50px 0 0 50px'}}>All <span
                                    className={`px-2 rounded-5 ${css.count}`}>{totalCount.all}</span></button>
                                <button type="button"
                                        className={`d-flex flex-col align-items-center gap-2 ${searchData.type === 'free' ? css.activeButton : css.freeFilterButtons}`}
                                        onClick={() => handleActiveButton('free')}>Free <span
                                    className={`px-2 rounded-5 ${css.count}`}>{totalCount.free}</span></button>
                                <button type="button"
                                        className={`d-flex flex-col align-items-center gap-2 ${searchData.type === 'paid' ? css.activeButton : css.paidFilterButtons}`}
                                        style={{borderRadius: '0 50px 50px 0'}}
                                        onClick={() => handleActiveButton('paid')}>Paid <span
                                    className={`px-2 rounded-5 ${css.count}`}>{totalCount.paid}</span></button>
                            </div>
                        </Col>
                        <Col xs={12} sm={6} md={8} lg={8} className='d-flex'>
                            <div className="d-flex flex-1 search-input flex-col align-items-end">
                                    <InputGroup className={css.searchInput}>
                                        <InputGroup.Text id="basic-addon1"><FontAwesomeIcon
                                            icon={faMagnifyingGlass}/></InputGroup.Text>
                                        {/* <Filter /> */}
                                        <input name="table-filter" value={searchText} onChange={searchTxt} placeholder="Enter text..." type="text" className="form-control" />
                                    </InputGroup>
                            </div>
                            {showRejectBtn && (
                                <button type="button" className={`secondary ${css.rejectBtn} border-0 py-2 px-3 ${css.downloadButton}`} onClick={rejectRequests}>
                                    <FontAwesomeIcon icon={faClose}></FontAwesomeIcon> Reject Request
                                </button>
                            )}
                        </Col>
                    </Row>
                    {/* <Table className='request_table'>
                        <TableHeader/>
                        <TableBody/>
                    </Table> */}
                    <div className='position-relative request-table-scroll'>
                        {loading && (
                            <div className='table-loader'>
                                <div className="ring">Downloading
                                    <span></span>
                                </div>
                            </div>
                        )}
                            <DataTable
                                className='imageTable requestTable'
                                pagination
                                paginationServer
                                selectableRows
                                columns={columns}
                                data={body}
                                progressPending={false}
                                paginationTotalRows={totalCount[searchData.type == ""?"all":searchData.type]}
                                selectableRowsNoSelectAll={false}
                                paginationDefaultPage={currentPage}
                                paginationRowsPerPageOptions={[10,20,50,100]}
                                paginationPerPage={rowsPerPage}
                                // onRowMouseEnter={handleMouseEnter}
                                // onRowMouseLeave={handleMouseLeave}
                                onChangePage={handlePageChange}
                                onChangeRowsPerPage={handleRowsPerPageChange}
                                onSelectedRowsChange={handleOnSelectedRowsChange}
                                // selectableRowSelected={handleApplySelectedRows}
                                />
                    </div>
                {/* </DatatableWrapper> */}
            </div>
            <Modal show={uploadModal} onHide={closeUploadModal} aria-labelledby="contained-modal-title-vcenter" centered >
                <h3 className="text-center m-4">Upload Images</h3>
                <div className="modal_body fix-height-modal p-3">
                    <form onSubmit={uploadAiImages}>
                        <div className='form-group mb-2'>
                            <label>AI images</label>
                            <input type='file' name='aiImageList' accept="image/*" multiple className='form-control' />
                        </div>
                        <div className='form-group text-center'>
                            <button type='button' className={`secondary rounded-3 border-0 py-2 px-2 m-2 ${css.issubmitDisable}`} onClick={closeUploadModal}>Cancel</button>
                            <button type='submit' className={`secondary rounded-3 border-0 py-2 px-2 ${css.submitButton}`}>Upload</button>
                        </div>
                    </form>
                </div>
            </Modal>

            <Modal show={imageModal} onHide={closeImageModal} size='lg' aria-labelledby="contained-modal-title-vcenter" centered >
                <div className='modal_header d-flex justify-content-between align-items-center'>
                    <h4 className="text-center m-4">Upload Images</h4>
                    <button type='button' className='close-btn m-3' onClick={closeImageModal}>Close</button>
                </div>
                <div className="modal_body image-grid-view d-inline-flex p-3">
                        {editData.imageList.map((img:string, imgIndex:number) => (
                            <div className='editView' key={"view_"+imgIndex}>
                                <img key={imgIndex} src={img} alt='' loading="lazy" className={css.footerImage}/>
                                <div className='actionBtn'>
                                    <button type='button' onClick={() => deleteAiImages(imgIndex)}><FontAwesomeIcon icon={faClose}></FontAwesomeIcon></button>
                                    <button type='button' onClick={() => openUploadModal(editData.imageId, imgIndex)}><FontAwesomeIcon icon={faRefresh}></FontAwesomeIcon></button>
                                </div>
                            </div>
                        ))}
                </div>
            </Modal>
        </>
    )
}

export default Requests