import css from './index.module.css'
import {Button} from "react-bootstrap";
import refImg from '../../assets/Ref.png';
import union from '../../assets/Union.svg';
import { useDispatch, useSelector } from 'react-redux';
import { ReducerStateIF } from '../../redux/reducers';
import API from '../../redux/services/Api';
import { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faEdit, faRefresh, faTrash } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { confirm } from "react-confirm-box";

const DashboardExample = () => {

    const userDetails = useSelector((state:ReducerStateIF) => state.app);
    const dispatch:any = useDispatch();
    const navigate = useNavigate()
    const [exampleList, setExampleList] = useState<any>([])

    useEffect(() => {
        getExampleData()
    },[])

    const getExampleData = async() => {
        const response:any = await API.Post("admin/dashboard-examples", {}, userDetails, dispatch)
        if(response.status === "success"){
            setExampleList(response.data)
        }
    }

    const editExample = (imgData:any) => {
        let tmpdata = {
            ...imgData,
            examples: imgData.examples.reverse()
        }
        navigate("/edit-dashboard-examples", {state: tmpdata})
    }

    const deleteExample = async(exampleId:string) => {
        const result = await confirm("Are you sure, you want to delete this row?");
        if (result) {
            const response:any = await API.Post("admin/delete-dashboard-example", {exampleId: exampleId}, userDetails, dispatch)
            if(response.status === "success"){
                getExampleData()
            }
        }
    }

    function handleOnDragEnd(result:any) {
        if (!result.destination) return;
    
        const items = Array.from(exampleList);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
    
        setExampleList((prevData:any) => items);
    }

    const updateExampleIndex = async() =>{
        try{
            const reqData = exampleList.map((e:any) => {
                return {
                    _id: e._id,
                    index: e.index
                }
            })
            const response:any = await API.Post("admin/dashboard-example-index-handler", {index:reqData}, userDetails, dispatch)
            if(response.status === "success"){
                toast.success(response.message)
            }else{
                toast.error(response.message)
            }

        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>
            <div className={`card examplePage p-3 mb-3 ${css.tableContainer}`}>
                <div className={`d-flex justify-content-between align-items-center ${css.actionsButton}`}>
                    <label>Dashboard Examples</label>
                    <div className="d-flex gap-3">
                        {exampleList.length > 1 && (
                            <Button type="button" variant="success" className={css.actionButtonSave} onClick={updateExampleIndex}>Update Index</Button>
                        )}
                        <Button type="submit" variant="success" className={css.actionButtonSave}><Link to={"/edit-dashboard-examples"} style={{color:"#fff", textDecoration: "none"}}>Add New</Link></Button>
                    </div>
                </div>
                <hr/>
                <div className='d-flex exImgList border-bottom-1px'>
                    <label className={`${css.indexRow} p-2`}>Name</label>
                    <div className={`${css.otherImg} m-auto `}>Desc</div>
                    <div className='actionMenu p-2'>Action</div>
                </div>
                <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="characters">
                        {(provided) => (
                            <ul className="characters p-0" {...provided.droppableProps} ref={provided.innerRef}>
                                {exampleList.length > 0 && exampleList.map((itemData:any, index:number) => (
                                      <React.Fragment key={itemData._id+"_drag"}>
                                        <Draggable key={itemData._id} draggableId={itemData._id} index={index}>
                                        {(provided) => (
                                            <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                <div className='d-flex exImgList justify-content-between p-3'>
                                                    <label className={css.indexRow}><img src={union} className='unionIcon'/> {itemData.name}</label>
                                                    <div className={`${css.otherImg} d-flex`}>
                                                        {itemData.desc}
                                                    </div>
                                                    <div className='actionMenu'>
                                                        <div className='d-flex'>
                                                            <button type='button' className='transparentBtn' onClick={() => editExample(itemData)}>
                                                                <FontAwesomeIcon icon={faEdit}></FontAwesomeIcon>
                                                            </button>
                                                            <button type='button' className='transparentBtn' onClick={() => deleteExample(itemData._id)}>
                                                                <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        )}
                                        </Draggable>
                                    </React.Fragment> 
                                ))}
                            </ul>
                         )}
                    </Droppable>
                </DragDropContext>
            </div>
        </>
    )
}

export default DashboardExample