import css from './index.module.css'
import {Button, Modal} from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { ReducerStateIF } from '../../redux/reducers';
import API from '../../redux/services/Api';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { confirm } from "react-confirm-box";

const EditDashboard = () => {

    const userDetails = useSelector((state:ReducerStateIF) => state.app);
    const dispatch:any = useDispatch();
    const location:any = useLocation();
    const navigate = useNavigate()
    const [imageModal, setImageModal] = useState(false)

    const initEditData = {
        exampleId: null,
        name: "",
        desc: "",
        examples: [],
    }
    const [editData, setEditData] = useState<any>(initEditData)

    // useEffect(() => {
    //     console.log(editData)
    // }, [editData])

    const initEditImageData = {
        index: null,
        image: null,
        promt: {
            male:"",
            female:"",
            negative:"",
            concatenate: ""
        } 
    }
    const [editImageData, setEditImageData] = useState<any>(initEditImageData)
    
    useEffect(() => {
        if(location.state){
            setEditData({
                exampleId: location.state._id,
                name: location.state.name,
                desc: location.state.desc,
                examples: location.state.examples,
            })
        }
    },[location.state])

    const viewImages = () => {
        setImageModal(true)
    }

    const editImages = (imgData?:any, index?:number) => {
        setEditImageData({
            index: index,
            ...imgData
        })
        setImageModal(true)
    }

    const closeImageModal = () => {
        setImageModal(false)
        setEditImageData(initEditImageData);
    }

    const onHandleMainImage = (e:any) => {
        setEditImageData((prevData:any) =>({...prevData, image: e.target.files[0]}));
    }

    const onHandleImgInputValue = (e:any) => {
        setEditImageData((prevData:any) =>({
            ...prevData, 
            promt: {
                ...prevData.promt,
                [e.target.name]: e.target.value
            }
        }));
    }

    const onHandleInputValue = (e:any) => {
        setEditData((prevData:any) =>({
            ...prevData,
            [e.target.name]:e.target.value
        }))
    }

    const addImageInList = () => {
        if(editImageData.index !== null){
            let tmpEditDataExamples = editData.examples;
                tmpEditDataExamples[editImageData.index] = {
                    image: editImageData.image,
                    promt: {
                        male: editImageData.promt.male,
                        female: editImageData.promt.female,
                        negative:editImageData.promt.negative,
                        concatenate: editImageData.promt.concatenate
                    }
                }
                setEditData((prevData:any) =>({
                    ...prevData,
                    examples: tmpEditDataExamples
                }))
        }else{
            setEditData((prevData:any) =>({
                ...prevData,
                examples: [
                    {
                        image: editImageData.image,
                        promt: {
                            male: editImageData.promt.male,
                            female: editImageData.promt.female,
                            negative:editImageData.promt.negative,
                            concatenate: editImageData.promt.concatenate
                        } 
                    },
                    ...prevData.examples
                ]
            }))
        }
        closeImageModal()
    }

    const uploadExample = async(e:any) => {
        e.preventDefault()
        try {
            if(!editData.name){
                toast.error("Please add name of this example")
                return false;
            }

            if(!editData.desc){
                toast.error("Please  add description about this example")
                return false;
            }

            let formData = new FormData();
            let tmpImgs = editData.examples.reverse()
            const examplesdata = tmpImgs.map((data:any) => {
                if(typeof data.image !== 'string'){
                    formData.append("dashImages", data.image);
                }
                return {
                    image: (typeof data.image !== 'string')?"":data.image,
                    promt: data.promt
                }
            })

            formData.append("name", editData.name.trim())
            formData.append("desc", editData.desc.trim())
            formData.append("examples", JSON.stringify(examplesdata))


            if(editData.exampleId){
                formData.append("exampleId", editData.exampleId)
            }

            const response:any = await API.Post("admin/dashboard-example-handler", formData, userDetails, dispatch, true)
            if(response.status === "success"){
                toast.success(response.message)
                navigate("/dashboard-examples");
            }else{
                toast.error(response.message)
            }

        } catch (error) {
            console.log(error)
        }
    }

    const deleteImages = async (index:number) => {
        const result = await confirm("Are you sure, you want to delete this row?");
        if (result) {
            let tmpPrevData = editData.examples;
            tmpPrevData.splice(index, 1);
            setEditData((prevData:any) => ({
                ...prevData,
                examples: tmpPrevData
            }))
        }
    }

    return (
        <>
            <div className={`card examplePage p-3 mb-3 ${css.tableContainer}`}>
                <form action='#' method='post' onSubmit={uploadExample}>
                    <div className={`d-flex justify-content-between align-items-center ${css.actionsButton}`}>
                        <label>Example Deails</label>
                        <div className="d-flex gap-3">
                            <Button type="button" variant="success" className={css.actionButtonCancel}><Link to={"/dashboard-examples"} style={{color:"#1C5E3C", textDecoration: "none"}}>Cancel</Link></Button>
                            <Button type="submit" variant="success" className={css.actionButtonSave}>Save Example</Button>
                        </div>
                    </div>
                    <div className="d-inline-block w-100 gap-3">
                        <div className='row'>
                            <div className="text-start col-md-12">
                                <div className='row'>
                                    <div className="col-md-6">
                                        <div className="mb-3 text-start">
                                            <label className="Settings_label__4KH6A form-label" htmlFor="formBasicEmail">Name</label>
                                            <input name="name" required type="text" id="formBasicEmail" value={editData.name} onChange={onHandleInputValue} className="form-control"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="mb-3 text-start">
                                            <label className="Settings_label__4KH6A form-label" htmlFor="formBasicDesc">Description</label>
                                            <textarea name="desc" required id="formBasicDesc" rows={3} value={editData.desc} onChange={onHandleInputValue} className="form-control"></textarea>
                                        </div>
                                    </div>
                                </div>
                                <br/>
                                <div className='exmapleImages'>
                                    <div className={`d-flex justify-content-between align-items-center ${css.actionsButton}`}>
                                        <label>Example Deails</label>
                                        <div className="d-flex gap-3">
                                            <Button type="button" variant="success" className={css.actionButtonSave} onClick={viewImages}>Add Images</Button>
                                        </div>
                                    </div>
                                    <table className='table table-border example-dash-tables'>
                                        <thead>
                                            <tr>
                                                <th style={{width: "150px"}}>Image</th>
                                                <th>Male</th>
                                                <th>Female</th>
                                                <th>Negative</th>
                                                <th>Concatenate</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {editData.examples.map((e:any, ind:number) => (
                                                <tr>
                                                    <td><img className={css.tableImages} src={(typeof e.image === 'string')?(e.image):URL.createObjectURL(e.image)} /></td>
                                                    <td><p>{e.promt.male}</p></td>
                                                    <td><p>{e.promt.female}</p></td>
                                                    <td><p>{e.promt.negative}</p></td>
                                                    <td><p>{e.promt.concatenate}</p></td>
                                                    <td>
                                                        <div className='actionMenu'>
                                                            <div className='d-flex'>
                                                                <button type='button' className='transparentBtn' onClick={() => editImages(e, ind)}>
                                                                    <FontAwesomeIcon icon={faEdit}></FontAwesomeIcon>
                                                                </button>
                                                                <button type='button' className='transparentBtn' onClick={() => deleteImages(ind)}>
                                                                    <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    <Modal show={imageModal} onHide={closeImageModal} size='lg' aria-labelledby="contained-modal-title-vcenter" centered >
                                        <div className="text-start p-5">
                                            <label className={css.label}>Main</label>
                                            <div className={`${css.imagepart} d-flex align-items-center`}>
                                                <label className="uplodImageCard" htmlFor="image"></label>
                                                <input type="file" id="image" onChange={onHandleMainImage} className='d-none'/>
                                                <div className='editView'>
                                                    {editImageData.image && (
                                                        <div className='editView'>
                                                            <img key={"exImg"} src={(typeof editImageData.image === 'string')?(editImageData.image):URL.createObjectURL(editImageData.image)} alt='' className={`exampleimage mr-05`}/>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <br/>
                                            <div className="mb-3 text-start">
                                                <label className="Settings_label__4KH6A form-label" htmlFor="name">Male</label>
                                                <input name="male" type="text" id="male" value={editImageData.promt.male} onChange={onHandleImgInputValue} className="form-control"
                                                />
                                            </div>
                                            <div className="mb-3 text-start">
                                                <label className="Settings_label__4KH6A form-label" htmlFor="formBasicEmail">Female</label>
                                                <input name="female" type="text" id="female" value={editImageData.promt.female} onChange={onHandleImgInputValue} className="form-control"
                                                />
                                            </div>
                                            <div className="mb-3 text-start">
                                                <label className="Settings_label__4KH6A form-label" htmlFor="negative">Negative</label>
                                                <input name="negative" type="text" id="negative" value={editImageData.promt.negative} onChange={onHandleImgInputValue} className="form-control"
                                                />
                                            </div>
                                            <div className="mb-3 text-start">
                                                <label className="Settings_label__4KH6A form-label" htmlFor="concatenate">Concatenate</label>
                                                <input name="concatenate" type="text" id="concatenate" value={editImageData.promt.concatenate} onChange={onHandleImgInputValue} className="form-control"
                                                />
                                            </div>
                                            <div className="mb-3 text-end">
                                                <Button type="button" variant="success" className={css.actionButtonSave} onClick={addImageInList}>Update</Button>
                                            </div>
                                        </div>
                                    </Modal>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

export default EditDashboard;